
import ApiService from "@/core/services/ApiService";
import {computed, defineComponent, onMounted, ref, toRef} from "vue";
import UserCU from "@/views/users/UsersCU.vue";
import router from "@/router";
import { useStore } from "vuex";
export default defineComponent({
  name: "KtAccount",
  components: {
    UserCU,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const permisionsAssigned = ref(0);
    const showPermissionCount = ref(true);
    const permissions = toRef(props.profile, "permissions_list");
    const currentUserPermissions = computed(() => store.getters.permissions);

    const getPerPermissions = () => {
      if (currentUserPermissions.value.filter((x) => x.name.includes("permissions: ")).length > 0) {
        try {
          let activePerm = 0;
          let totalPerm = 0;
          permissions.value.forEach(function (val) {
            val.permissions.forEach( perm => {
              if (perm.active == true) {
                activePerm += 1
              }
            })
          });
          ApiService.get("/api/permissions").then(({ data }) => {
            data.forEach(function (val) {
              val.permissions.forEach( perm => {
                totalPerm += 1
              })
            });
            const per = Math.round(
                (activePerm / totalPerm) * 100
            );
            permisionsAssigned.value = per;
            store.commit("setLoadingStatus", false);
          });
        } catch (err) {
          console.error(err);
          store.commit("setLoadingStatus", false);
        }
      } else {
          showPermissionCount.value = false
        store.commit("setLoadingStatus", false);
      }
    };
    onMounted(() => {
      getPerPermissions();
    });

    const successOperation = (response) => {
      router.push(`/users/${response.data.id}/general`);
    };
    return {
      successOperation,
      permisionsAssigned,
      sita_path: document.location.origin,
      storageHost: process.env.VUE_APP_API_URL,
      store,
      showPermissionCount,
      currentUserPermissions
    };
  },
});
