<template>
  <div>
    <Account v-if="user.id" :profile="user" />
    <router-view v-if="user" :key="$route.path" :profile="user"></router-view>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Account from "./Account.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import router from "@/router";

export default defineComponent({
  name: "PageUser",
  components: {
    Account,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const user = ref({});

    const permissions = computed(() => store.getters.permissions);
    const canReadUser = computed(() => {
      return (
          permissions.value.findIndex(
              (x) => x.name === "users: show"
          ) !== -1
      );
    });

    const getUser = async () => {
      store.commit("setOffices", []);
      try {
        const usera = computed(() =>
          JSON.parse(localStorage.getItem("user") || "{}")
        );
        let result = route.path.replace("undefined", usera.value.id);
        if (route.params.user_id) {
          if (route.params.user_id === "add") {
            user.value = { id: "add" };
          } else {
            if (route.params.user_id === "undefined") {
              ApiService.get("/api/auth/me").then(function (response) {
                store.commit("setUserData", response.data);
                store.commit("setOffices", response.data.offices);
                route.params.user_id == usera.value.id
                user.value = response.data;
              });
              router.push({
                path: result,
              });
            } else {
              let endpoint = route.params.user_id == usera.value.id ? '/api/auth/me' : `/api/users/${route.params.user_id}`;
              if (canReadUser || (route.params.user_id == usera.value.id)) {
                ApiService.get(endpoint).then(
                    function (response) {
                      store.commit("setUserData", response.data);
                      store.commit("setOffices", response.data.offices);
                      user.value = response.data;
                    }
                );
                user.value.myself = false;
              }
            }
          }
        } else {
          setCurrentPageTitle("profile");
          user.value = JSON.parse(localStorage.getItem("user"));
          // user.value = await ApiService.get(`/api/auth/me`).then(function (
          //   response
          // ) {
          //   store.commit("setUserData", response.data);
          //   user.value = response.data;
          //   setTimeout(function () {
          //     // router.push("/user/" + response.data.id + "/overview");
          //   }, 100);
          // });
          user.value.myself = false;
          // user.value.myself = true;
        }
      } catch (error) {
        console.error(error);
      }
    };

    watch(
      () => [route.params.user_id, store.getters.currentUser],
      async ([user_id, user], [oldId, oldUs]) => {
        if (user_id !== oldId || user !== oldUs) {
          await getUser();
        }
      }
    );

    onMounted(() => {
        getUser();
    });

    return {
      user,
      canReadUser,
    };
  },
});
</script>
