
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/helpers/functions";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import router from "@/router";
interface FormUser {
  id?: number;
  name: string;
  born_date: string;
  phone_number: string;
  cif_nif: string;
  email: string;
  password: string;
  avatar: string;
}
export default defineComponent({
  name: "UsersCU",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    typeEvent: String,
    item: Object,
  },
  emits: ["success"],
  setup(props, { emit }) {
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const title = ref("");
    let avatar: any;
    const form = ref<FormUser>({
      name: "",
      born_date: "",
      phone_number: "",
      cif_nif: "",
      email: "",
      password: "",
      avatar: "",
    });
    const inputId = ref({
      loading: false,
      options: [],
      list: [],
    });

    const getUsers = () => {
      ApiService.get("/api/users").then(({ data }) => {
        inputId.value.list = data;
      });
    };

    const selectUser = (query) => {
      if (query !== "") {
        inputId.value.loading = true;
        setTimeout(() => {
          inputId.value.loading = false;
          inputId.value.options = inputId.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputId.value.options = [];
      }
    };

    const setUser = (id) => {
      const item = inputId.value.list.find((item: Record<string, any>) => {
        return item.id === id;
      }) || {
        id: "",
        name: "",
        born_date: "",
        phone_number: "",
        cif_nif: "",
        email: "",
        password: "",
        avatar: "",
      };
      form.value.name = item.name;
      form.value.born_date = item.born_date;
      form.value.cif_nif = item.cif_nif;
      form.value.email = item.email;
      form.value.password = item.password;
      form.value.avatar = item.avatar;
    };

    const previewImage = (event) => {
      const [file] = event.target.files;
      if (file) {
        form.value[event.target.name] = URL.createObjectURL(file);
        if (event.target.name === "avatar") {
          avatar = event.target.files[0];
        }
      }
    };

    const removeImage = (input) => {
      form.value[input] = "media/defaults/blank.png";
      if (input === "avatar") {
        avatar = "";
      }
    };

    onMounted(() => {
      const nameRoute = route.name;
      if (nameRoute === "userCreate" || props.typeEvent === "create") {
        title.value = "Add new user";
      } else if (nameRoute === "usersUpdate" || props.typeEvent === "update") {
        title.value = "Actualizar usuario";
      }

      if (props.item && Object.keys(props.item).length > 0) {
        form.value.id = props.item.id;
        form.value.name = props.item.name;
        form.value.born_date = props.item.born_date || "";
        form.value.phone_number = props.item.phone_number || "";
        form.value.cif_nif = props.item.cif_nif || "";
        form.value.email = props.item.email || "";
        form.value.password = props.item.password || "";
        form.value.avatar = props.item.avatar;
      }
      if (nameRoute === "usersUpdate") {
        getUsers();
      }

      if (!props.typeEvent) {
        const nav = nameRoute == "userCreate" ? "userCreate" : "usersUpdate";
        setCurrentPageBreadcrumbs(nav, ["user", "user"], false);
      }
    });

    const createUser = () => {
      const formData = new FormData();
      formData.append("name", form.value.name);
      formData.append("email", form.value.email);
      formData.append("password", form.value.password);
      formData.append("avatar", avatar);
      /*formData.append("born_date", form.value.born_date);
      formData.append("nif_cif", form.value.cif_nif);
      formData.append("phone", form.value.phone_number);*/
      ApiService.postAttach("/api/users", formData)
        .then(({ data }) => {
          router.push("/user/" + data.id + "/overview");
          // emit("success", data);
          // submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const updateUser = () => {
      const formData = new FormData();
      formData.append("name", form.value.name);
      formData.append("email", form.value.email);
      formData.append("avatar", avatar);
      ApiService.postAttach(`/api/users/update/${form.value.id}`, formData)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const vusers = Yup.object().shape({
      name: Yup.string().required(translate("rname")).label("Name"),
      email: Yup.string().required().email().label("Email"),
      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          translate("rpasswordrequired")
        ),
    });

    const onSubmitUser = () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateUser();
        } else {
          createUser();
        }
      }
    };

    return {
      route,
      title,
      submitButton,
      vusers,
      form,
      avatar,
      onSubmitUser,
      createUser,
      updateUser,
      inputId,
      getUsers,
      selectUser,
      setUser,
      previewImage,
      removeImage,
    };
  },
});
